var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.vacantShiftsLoading, rounded: "sm" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        attrs: {
                          config: {
                            enableTime: false,
                            noCalendar: false,
                            dateFormat: "Y-m-d",
                            dateFormat: "Y-m-d",
                            locale: {
                              firstDayOfWeek: 1
                            }
                          },
                          placeholder: "Select Date"
                        },
                        model: {
                          value: _vm.filters["start"],
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "start", $$v)
                          },
                          expression: "filters['start']"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "8" } })
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.tableLoading, rounded: "sm" } },
                    [
                      _c("b-table", {
                        ref: "tableApplyPcn",
                        staticClass: "mobile_table_css",
                        attrs: {
                          "current-page": _vm.currentPage,
                          "per-page": _vm.pagination.perPage,
                          fields: _vm.fields,
                          filter: _vm.filter,
                          "filter-included-fields": _vm.filterOn,
                          items: _vm.getVacantShiftApplications,
                          "sort-by": _vm.sortBy,
                          eventDoubleClick: false,
                          "sort-desc": _vm.sortDesc,
                          "sort-direction": _vm.sortDirection,
                          hover: "",
                          responsive: ""
                        },
                        on: {
                          "update:sortBy": function($event) {
                            _vm.sortBy = $event
                          },
                          "update:sort-by": function($event) {
                            _vm.sortBy = $event
                          },
                          "update:sortDesc": function($event) {
                            _vm.sortDesc = $event
                          },
                          "update:sort-desc": function($event) {
                            _vm.sortDesc = $event
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(action)",
                            fn: function(data) {
                              return [
                                data.item.applications == "Yes"
                                  ? _c(
                                      "b-badge",
                                      {
                                        staticClass: "text-capitalize",
                                        attrs: { variant: "light-success" }
                                      },
                                      [_vm._v(" appled ")]
                                    )
                                  : _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.applyVacantShifts(
                                              data.item.id
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Apply")]
                                    )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.noDataTable === 0
                ? _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [_c("span", [_vm._v("No data for preview")])]
                  )
                : _vm._e(),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-start",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _vm.pagination.totalRows !== 0
                    ? _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          "Showing " +
                            _vm._s(_vm.pagination.from) +
                            " to " +
                            _vm._s(_vm.pagination.to) +
                            " of " +
                            _vm._s(_vm.pagination.totalRows) +
                            " entries"
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-end",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c("b-pagination", {
                    staticClass: "mb-0 mt-1 mt-sm-0 ",
                    attrs: {
                      "current-page": _vm.currentPage,
                      "per-page": _vm.pagination.perPage,
                      "total-rows": _vm.pagination.totalRows,
                      "first-number": "",
                      "last-number": "",
                      "next-class": "next-item",
                      "prev-class": "prev-item"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prev-text",
                        fn: function() {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronLeftIcon", size: "18" }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "next-text",
                        fn: function() {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronRightIcon", size: "18" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }