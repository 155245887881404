var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.data.state === "published" || _vm.data.state === "pending"
      ? _c("div", [
          _c("div", { staticClass: "time" }, [
            _vm._v(
              " " +
                _vm._s(_vm.momentFormat(_vm.data.StartTime, "HH:mm") + " - ") +
                " " +
                _vm._s(_vm.momentFormat(_vm.data.EndTime, "HH:mm")) +
                " "
            )
          ]),
          _vm.data.Subject
            ? _c("div", [
                _vm.data.state === "draft"
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "background-color": "#FFFFFF",
                          "border-radius": "3px",
                          padding: "1px",
                          color: "#4361EE"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.data.Subject))]
                    )
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "background-color": "#FFFFFF",
                          "border-radius": "3px",
                          padding: "1px",
                          color: "#00C49A",
                          "word-wrap": "break-word"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.data.Subject))]
                    )
              ])
            : _vm._e(),
          _vm.data.is_pcn
            ? _c("div", { staticClass: "text-wrap" }, [
                _vm._v(" " + _vm._s(_vm.data.programme.name) + " ")
              ])
            : _vm._e(),
          _vm.data.is_pcn
            ? _c("div", { staticClass: "text-wrap" }, [
                _vm._v(" " + _vm._s(_vm.data.location.name) + " ")
              ])
            : _vm._e(),
          _vm.data.shift_type
            ? _c("div", { staticClass: "text-wrap" }, [
                _vm._v(" " + _vm._s(_vm.data.shift_type.name) + " ")
              ])
            : _vm._e()
        ])
      : _c("div", [
          _c("div", { staticClass: "time" }, [
            _c("del", [
              _vm._v(
                _vm._s(_vm.momentFormat(_vm.data.StartTime, "HH:mm") + " - ")
              )
            ]),
            _c("del", [
              _vm._v(_vm._s(_vm.momentFormat(_vm.data.EndTime, "HH:mm")))
            ])
          ]),
          _vm.data.Subject
            ? _c("div", [
                _vm.data.state === "draft"
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "background-color": "#FFFFFF",
                          "border-radius": "3px",
                          padding: "1px",
                          color: "#4361EE"
                        }
                      },
                      [_c("del", [_vm._v(_vm._s(_vm.data.Subject))])]
                    )
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "background-color": "#FFFFFF",
                          "border-radius": "3px",
                          padding: "1px",
                          color: "#00C49A",
                          "word-wrap": "break-word"
                        }
                      },
                      [_c("del", [_vm._v(_vm._s(_vm.data.Subject))])]
                    )
              ])
            : _vm._e(),
          _vm.data.is_pcn
            ? _c("div", [_c("del", [_vm._v(_vm._s(_vm.data.programme.name))])])
            : _vm._e(),
          _vm.data.is_pcn
            ? _c("div", [_c("del", [_vm._v(_vm._s(_vm.data.location.name))])])
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }