var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.selectedCell.length !== 0
      ? _c("div", { staticClass: "p-1" }, [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _vm.selectedCell.Subject !== "."
                    ? _c("div", { staticClass: "font-weight-bold mr-1 " }, [
                        _vm._v(_vm._s(_vm.selectedCell.Subject))
                      ])
                    : _vm._e(),
                  _vm.selectedCell.is_covid_shift
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "mr-1",
                          attrs: { size: "sm", variant: "danger" }
                        },
                        [_vm._v(" COVID-19 ")]
                      )
                    : _vm._e(),
                  _vm.selectedCell.service.type === "clinical"
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "text-capitalize",
                          attrs: { size: "sm", variant: "light-success" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.selectedCell.service.type) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.selectedCell.service.type === "non-clinical"
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "text-capitalize",
                          attrs: { size: "sm", variant: "light-info" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.selectedCell.service.type) + " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("b-row", [
                _vm.selectedCell.shift_type
                  ? _c("div", { staticClass: "font-weight-bold mr-1  " }, [
                      _vm._v(_vm._s(_vm.selectedCell.shift_type.name))
                    ])
                  : _vm._e()
              ]),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "CalendarIcon" }
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.momentFormat(
                          _vm.selectedCell.StartTime,
                          "ddd DD MMMM YYYY"
                        )
                      ) +
                      " "
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "ClockIcon" }
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.momentFormat(_vm.selectedCell.StartTime, "HH:mm")
                      ) +
                      " - " +
                      _vm._s(
                        _vm.momentFormat(_vm.selectedCell.EndTime, "HH:mm")
                      ) +
                      " "
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "MapPinIcon" }
                  }),
                  _vm.selectedCell.is_pcn
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.selectedCell.location.name))
                      ])
                    : _vm._e(),
                  !_vm.selectedCell.is_pcn
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.selectedCell.practice.name))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm.selectedCell.user
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "UsersIcon" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedCell.user.first_name +
                              " " +
                              _vm.selectedCell.user.last_name
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell.State === "vacant"
                ? _c("b-row", { staticClass: "mt-1" }, [
                    _vm._v(
                      " Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
                    )
                  ])
                : _vm._e(),
              _vm.selectedCell.state === "published" &&
              _vm.selectedCell.user === null &&
              _vm.selectedCell.is_pcn === false
                ? _c(
                    "b-row",
                    { staticClass: "mt-1 ms-2" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary", size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.applyMPVacentshifts(
                                _vm.selectedCell.Id
                              )
                            }
                          }
                        },
                        [_vm._v("Apply")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell.state === "published" &&
              _vm.selectedCell.user === null &&
              _vm.selectedCell.is_pcn === true
                ? _c(
                    "b-row",
                    { staticClass: "mt-1 ms-2" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary", size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.applyPCNVacentshift(
                                _vm.selectedCell.Id
                              )
                            }
                          }
                        },
                        [_vm._v("Apply")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }