var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "pl-2", attrs: { cols: "12", md: "3" } }),
          _c("b-col", { attrs: { cols: "12", md: "3" } }),
          _c("b-col", { attrs: { cols: "12", md: "3" } }),
          _c("b-col", { attrs: { cols: "12", md: "3" } }, [
            _c(
              "div",
              { staticClass: "flex float-right" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(255, 255, 255, 0.15)",
                        expression: "'rgba(255, 255, 255, 0.15)'",
                        modifiers: { "400": true }
                      }
                    ],
                    staticClass: "mr-2 swapButton",
                    attrs: { variant: "white" },
                    on: {
                      click: function($event) {
                        return _vm.openSwapRequestMode()
                      }
                    }
                  },
                  [
                    _c("feather-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "RepeatIcon" }
                    }),
                    _vm.swapRequestListLength === 0
                      ? _c("span", [_vm._v("Swap Requests")])
                      : _vm._e(),
                    _vm.swapRequestListLength === 1
                      ? _c("span", [
                          _vm._v("Swap Request "),
                          _c(
                            "span",
                            {
                              staticClass: "text-white",
                              staticStyle: {
                                "background-color": "#ff4848",
                                padding: "5px",
                                "border-radius": "30px",
                                "margin-left": "5px"
                              }
                            },
                            [_vm._v("1")]
                          )
                        ])
                      : _vm._e(),
                    _vm.swapRequestListLength >= 2
                      ? _c("span", [
                          _vm._v("Swap Requests "),
                          _c(
                            "span",
                            {
                              staticClass: "text-white",
                              staticStyle: {
                                "background-color": "#ff4848",
                                padding: "5px",
                                "border-radius": "30px",
                                "margin-left": "5px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.swapRequestListLength) + " ")]
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(113, 102, 240, 0.15)",
                        expression: "'rgba(113, 102, 240, 0.15)'",
                        modifiers: { "400": true }
                      },
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.top",
                        value: "Print",
                        expression: "'Print'",
                        modifiers: { hover: true, top: true }
                      }
                    ],
                    staticClass: "btn-icon mr-1",
                    attrs: { variant: "lighten-2" },
                    on: {
                      click: function($event) {
                        return _vm.printData()
                      }
                    }
                  },
                  [
                    _c("feather-icon", {
                      staticStyle: { width: "20px", height: "20px" },
                      attrs: { "font-scale": "2", icon: "PrinterIcon" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("b-col", { staticClass: "mt-2", attrs: { cols: "12", md: "12" } })
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "SearchIcon" }
                              })
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            attrs: { id: "icons-search", placeholder: "Search" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "9" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-2",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: [
                                { title: "All", value: "all" },
                                { title: "Vacant", value: "vacants" },
                                { title: "Filled", value: "filleds" },
                                { title: "Approved", value: "approved" },
                                { title: "Cancelled", value: "cancelled" }
                              ],
                              label: "title",
                              placeholder: "All Shifts"
                            },
                            model: {
                              value: _vm.selectedShiftType,
                              callback: function($$v) {
                                _vm.selectedShiftType = $$v
                              },
                              expression: "selectedShiftType"
                            }
                          })
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { md: "5" } }),
                      _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple.400",
                                  value: "rgba(113, 102, 240, 0.15)",
                                  expression: "'rgba(113, 102, 240, 0.15)'",
                                  modifiers: { "400": true }
                                }
                              ],
                              staticClass: "btn-icon mr-1 float-right",
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.openApplyShiftsModel()
                                }
                              }
                            },
                            [_vm._v("Vacant Shifts ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "schedule-vue-sample" }, [
            _c("div", { staticClass: "col-md-12 control-section" }, [
              _c(
                "div",
                { staticClass: "content-wrapper" },
                [
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.calendarLoading, rounded: "sm" } },
                    [
                      _c(
                        "ejs-schedule",
                        {
                          ref: "scheduler",
                          attrs: {
                            height: "auto",
                            dataBinding: _vm.dataBinding,
                            selectedDate: _vm.selectedDate,
                            eventSettings: _vm.eventSettings,
                            eventRendered: _vm.onEventRendered,
                            quickInfoTemplates: _vm.quickInfoTemplates,
                            popupOpen: _vm.openpopup,
                            eventClick: _vm.onEventClick,
                            eventDoubleClick: _vm.onEventClick,
                            timeScale: _vm.timeScale,
                            startHour: _vm.startHour
                          }
                        },
                        [
                          _c(
                            "e-views",
                            [
                              _c("e-view", {
                                attrs: {
                                  eventTemplate: _vm.weekTemplate,
                                  displayName: "WEEK",
                                  option: "Week"
                                }
                              }),
                              _c("e-view", {
                                attrs: {
                                  eventTemplate: _vm.monthTemplate,
                                  displayName: "MONTH",
                                  option: "Month"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-center",
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            size: "xl",
            title: "Vacant Shifts"
          },
          on: { change: _vm.updateState },
          model: {
            value: _vm.showApplyVacantModel,
            callback: function($$v) {
              _vm.showApplyVacantModel = $$v
            },
            expression: "showApplyVacantModel"
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.vacantShiftsLoading, rounded: "sm" } },
            [
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-alert",
                        { attrs: { show: "", variant: "primary" } },
                        [
                          _c(
                            "div",
                            { staticClass: "alert-body" },
                            [
                              _c("feather-icon", {
                                staticClass: "mr-25",
                                attrs: { icon: "AlertCircleIcon" }
                              }),
                              _c("span", { staticClass: "ml-25" }, [
                                _vm._v(
                                  "All listed shifts are applicable to your role only. " +
                                    _vm._s(
                                      _vm.momentFormat(new Date(), "DD-MMM-YY")
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.momentFormat(new Date(), "HH:mm")
                                    )
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-1" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("flat-pickr", {
                                    staticClass: "form-control",
                                    attrs: {
                                      config: {
                                        enableTime: false,
                                        noCalendar: false,
                                        dateFormat: "Y-m-d",
                                        dateFormat: "Y-m-d",
                                        locale: {
                                          firstDayOfWeek: 1
                                        }
                                      },
                                      placeholder: "Select Date"
                                    },
                                    model: {
                                      value: _vm.filters["start"],
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "start", $$v)
                                      },
                                      expression: "filters['start']"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "8" } },
                            [
                              _c(
                                "b-row",
                                { staticClass: "mr-1" },
                                [
                                  _c("b-col", [
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "flex float-right list-inline mb-0"
                                      },
                                      [
                                        _c(
                                          "li",
                                          { staticClass: "list-inline-item" },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                attrs: {
                                                  name: "check-button",
                                                  switch: ""
                                                },
                                                on: { change: _vm.stateChange },
                                                model: {
                                                  value: _vm.show_vacant,
                                                  callback: function($$v) {
                                                    _vm.show_vacant = $$v
                                                  },
                                                  expression: "show_vacant"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "switch-icon-left"
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: {
                                                        icon: "CheckIcon"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "switch-icon-right"
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: { icon: "XIcon" }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass:
                                              "list-inline-item text-secondary"
                                          },
                                          [
                                            _vm._v(
                                              " Show vacant shifts on my calendar "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-overlay",
                                {
                                  attrs: {
                                    show: _vm.tableLoading,
                                    rounded: "sm"
                                  }
                                },
                                [
                                  _c("b-table", {
                                    ref: "table",
                                    staticClass: "mobile_table_css",
                                    attrs: {
                                      "current-page": _vm.currentPage,
                                      "per-page": _vm.pagination.perPage,
                                      fields: _vm.fields,
                                      filter: _vm.filter,
                                      "filter-included-fields": _vm.filterOn,
                                      items: _vm.getVacantShiftApplications,
                                      "sort-by": _vm.sortBy,
                                      eventClick: _vm.onEventClick,
                                      eventDoubleClick: _vm.onEventClick,
                                      "sort-desc": _vm.sortDesc,
                                      "sort-direction": _vm.sortDirection,
                                      popupOpen: _vm.openpopup,
                                      hover: "",
                                      responsive: ""
                                    },
                                    on: {
                                      "update:sortBy": function($event) {
                                        _vm.sortBy = $event
                                      },
                                      "update:sort-by": function($event) {
                                        _vm.sortBy = $event
                                      },
                                      "update:sortDesc": function($event) {
                                        _vm.sortDesc = $event
                                      },
                                      "update:sort-desc": function($event) {
                                        _vm.sortDesc = $event
                                      }
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "cell(action)",
                                        fn: function(data) {
                                          return [
                                            data.item.applications == "Yes"
                                              ? _c(
                                                  "b-badge",
                                                  {
                                                    staticClass:
                                                      "text-capitalize",
                                                    attrs: {
                                                      variant: "light-success"
                                                    }
                                                  },
                                                  [_vm._v(" Applied ")]
                                                )
                                              : _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant: "primary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.applyVacantShifts(
                                                          data.item.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Apply")]
                                                )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.noDataTable === 0
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12" }
                                },
                                [_c("span", [_vm._v("No data for preview")])]
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "\n                d-flex\n                align-items-center\n                justify-content-center justify-content-sm-start\n              ",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _vm.pagination.totalRows !== 0
                                ? _c("span", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      "Showing " +
                                        _vm._s(_vm.pagination.from) +
                                        " to " +
                                        _vm._s(_vm.pagination.to) +
                                        " of " +
                                        _vm._s(_vm.pagination.totalRows) +
                                        " entries"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "\n                d-flex\n                align-items-center\n                justify-content-center justify-content-sm-end\n              ",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("b-pagination", {
                                staticClass: "mb-0 mt-1 mt-sm-0",
                                attrs: {
                                  "per-page": _vm.pagination.perPage,
                                  "total-rows": _vm.pagination.totalRows,
                                  "first-number": "",
                                  "last-number": "",
                                  "next-class": "next-item",
                                  "prev-class": "prev-item"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prev-text",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "ChevronLeftIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "next-text",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "ChevronRightIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.currentPage,
                                  callback: function($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              false
                ? _c("b-card", [
                    _c("div", { staticClass: "text-center p-5" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "250",
                            height: "250",
                            viewBox: "0 0 160 160",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z",
                              fill: "#F0F8FC"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M136.17 57.8711C138.52 57.8711 140.425 59.7766 140.425 62.1264C140.425 64.4762 138.52 66.3817 136.17 66.3817C133.819 66.3817 131.915 64.4762 131.915 62.1264C131.915 59.7766 133.819 57.8711 136.17 57.8711Z",
                              fill: "#DCEEF8"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M148.085 44.2539C151.375 44.2539 154.043 46.9211 154.043 50.2114C154.043 53.5016 151.375 56.1688 148.085 56.1688C144.795 56.1688 142.128 53.5016 142.128 50.2114C142.128 46.9211 144.795 44.2539 148.085 44.2539Z",
                              fill: "#DCEEF8"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M130.213 42.5547C131.153 42.5547 131.915 43.3164 131.915 44.2568C131.915 45.1972 131.153 45.9589 130.213 45.9589C129.272 45.9589 128.511 45.1972 128.511 44.2568C128.511 43.3164 129.272 42.5547 130.213 42.5547Z",
                              fill: "#DCEEF8"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M43.8595 40.457H116.533C120.545 40.457 123.797 43.7089 123.797 47.7209V120.394C123.797 124.406 120.545 127.658 116.533 127.658H43.8595C39.8476 127.658 36.5957 124.406 36.5957 120.394V47.7209C36.5957 43.7089 39.8476 40.457 43.8595 40.457Z",
                              stroke: "#171822",
                              "stroke-width": "3"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M100.426 33.1914V52.5693",
                              stroke: "#1B9AAA",
                              "stroke-width": "3",
                              "stroke-linecap": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M36.5957 67.1016H122.877",
                              stroke: "#171822",
                              "stroke-width": "3"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M61.668 33.1914V52.5693",
                              stroke: "#1B9AAA",
                              "stroke-width": "3",
                              "stroke-linecap": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M55.9746 91.3252H65.6597V81.6367H55.9746V91.3252Z",
                              fill: "#CCE6F4"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M55.9746 100.296C55.9746 99.3555 56.7321 98.5938 57.6767 98.5938H63.9576C64.9023 98.5938 65.6597 99.3555 65.6597 100.296V106.58C65.6597 107.521 64.9023 108.282 63.9576 108.282H57.6767C56.7321 108.282 55.9746 107.521 55.9746 106.58V100.296Z",
                              fill: "#1B9AAA"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M75.3533 83.3388C75.3533 82.3984 76.1107 81.6367 77.0554 81.6367H83.3362C84.2809 81.6367 85.0384 82.3984 85.0384 83.3388V89.6231C85.0384 90.5635 84.2809 91.3252 83.3362 91.3252H77.0554C76.1107 91.3252 75.3533 90.5635 75.3533 89.6231V83.3388Z",
                              fill: "#1B9AAA"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M75.3533 100.296C75.3533 99.3555 76.1107 98.5938 77.0554 98.5938H83.3362C84.2809 98.5938 85.0384 99.3555 85.0384 100.296V106.58C85.0384 107.521 84.2809 108.282 83.3362 108.282H77.0554C76.1107 108.282 75.3533 107.521 75.3533 106.58V100.296Z",
                              fill: "#1B9AAA"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M94.7319 83.3388C94.7319 82.3984 95.4979 81.6367 96.4341 81.6367H102.723C103.66 81.6367 104.426 82.3984 104.426 83.3388V89.6231C104.426 90.5635 103.66 91.3252 102.723 91.3252H96.4341C95.4979 91.3252 94.7319 90.5635 94.7319 89.6231V83.3388Z",
                              fill: "#1B9AAA"
                            }
                          })
                        ]
                      ),
                      _c("br"),
                      _c("div", { staticClass: "mt-lg-1 text-large" }, [
                        _vm._v("Please Add Vacant Shifts")
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-center",
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            size: "xl",
            title: "Swap Requests"
          },
          model: {
            value: _vm.showSwapRequestModel,
            callback: function($$v) {
              _vm.showSwapRequestModel = $$v
            },
            expression: "showSwapRequestModel"
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.swapRequestLoading, rounded: "sm" } },
            [
              _vm.swapRequestList.length === 0
                ? _c(
                    "div",
                    {
                      staticClass: "text-center",
                      staticStyle: { height: "100px" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "font-weight-700 mt-3" },
                        [
                          _c(
                            "b-alert",
                            {
                              staticClass: "p-2",
                              attrs: { variant: "warning ", show: "" }
                            },
                            [
                              _c("span", { staticClass: "text-dark" }, [
                                _vm._v("You haven't any swap request!")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.swapRequestList, function(request) {
                return _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "10" } }, [
                          _c("ul", { staticClass: "list-inline mb-1" }, [
                            _c(
                              "li",
                              { staticClass: "list-inline-item" },
                              [
                                _c("b-avatar", {
                                  staticClass: "pull-up",
                                  attrs: {
                                    src: require("@/assets/images/portrait/small/avatar-s-5.jpg")
                                  }
                                })
                              ],
                              1
                            ),
                            _c("li", { staticClass: "list-inline-item" }, [
                              _c("span", { staticClass: "font-weight-700" }, [
                                _vm._v(
                                  _vm._s(
                                    request.shift.assignee.first_name +
                                      " " +
                                      request.shift.assignee.last_name
                                  )
                                )
                              ]),
                              _vm._v(" wants to swap their shift with yours. ")
                            ])
                          ])
                        ]),
                        _c("b-col", { attrs: { cols: "2" } }, [
                          _c(
                            "div",
                            { staticClass: "float-right" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: "Accept",
                                      expression: "'Accept'",
                                      modifiers: { hover: true, top: true }
                                    }
                                  ],
                                  staticClass: "btn-icon bg-white text-primary",
                                  attrs: {
                                    size: "23",
                                    variant: "outline-white"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.acceptSwapRequest(request.id)
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "CheckSquareIcon",
                                      size: "18"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: "Reject",
                                      expression: "'Reject'",
                                      modifiers: { hover: true, top: true }
                                    }
                                  ],
                                  staticClass:
                                    "btn-icon bg-white text-danger ml-1",
                                  attrs: {
                                    size: "23",
                                    variant: "outline-white"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.rejectSwapRequest(request.id)
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "XSquareIcon",
                                      size: "18",
                                      variant: "outline-danger"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("b-card", [
                              _c("div", { staticClass: "flex-centered mb-1" }, [
                                request.swap_shift.service !== null
                                  ? _c(
                                      "span",
                                      { staticClass: "font-weight-700 mr-1" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            request.swap_shift.service === null
                                              ? "test"
                                              : request.swap_shift.service.name
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  [
                                    request.swap_shift.is_covid_shift
                                      ? _c(
                                          "b-badge",
                                          { attrs: { variant: "danger" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-white font-weight-700"
                                              },
                                              [_vm._v(" COVID-19 ")]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "mb-1" },
                                [
                                  _c("feather-icon", {
                                    staticClass:
                                      "\n                    cursor-pointer\n                    e-round e-small\n                    text-primary\n                    font-weight-bolder\n                  ",
                                    attrs: { icon: "CalendarIcon", size: "16" }
                                  }),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.momentFormat(
                                            new Date(request.swap_shift.start),
                                            "dddd YYYY-MM-DD"
                                          )
                                        )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("feather-icon", {
                                    staticClass:
                                      "\n                    cursor-pointer\n                    e-round e-small\n                    text-primary\n                    font-weight-bolder\n                  ",
                                    attrs: { icon: "ClockIcon", size: "16" }
                                  }),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.momentFormat(
                                            new Date(request.swap_shift.start),
                                            "HH:mm"
                                          ) +
                                            "-" +
                                            _vm.momentFormat(
                                              new Date(request.swap_shift.end),
                                              "HH:mm"
                                            )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "d-flex justify-content-center",
                            attrs: { cols: "2" }
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mt-3 text-primary",
                              staticStyle: { width: "30px", height: "30px" },
                              attrs: { icon: "RepeatIcon" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("b-card", [
                              _c("div", { staticClass: "flex-centered mb-1" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-700 mr-1" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        request.shift.service === null
                                          ? ""
                                          : request.shift.service.name
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  [
                                    request.shift.is_covid_shift
                                      ? _c(
                                          "b-badge",
                                          { attrs: { variant: "danger" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-white font-weight-700 f"
                                              },
                                              [_vm._v(" COVID-19 ")]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "mb-1" },
                                [
                                  _c("feather-icon", {
                                    staticClass:
                                      "\n                    cursor-pointer\n                    e-round e-small\n                    text-primary\n                    font-weight-bolder\n                  ",
                                    attrs: { icon: "CalendarIcon", size: "16" }
                                  }),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.momentFormat(
                                            new Date(request.shift.start),
                                            "dddd YYYY-MM-DD"
                                          )
                                        )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("feather-icon", {
                                    staticClass:
                                      "\n                    cursor-pointer\n                    e-round e-small\n                    text-primary\n                    font-weight-bolder\n                  ",
                                    attrs: { icon: "ClockIcon", size: "16" }
                                  }),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.momentFormat(
                                            new Date(request.shift.start),
                                            "HH:mm"
                                          ) +
                                            "-" +
                                            _vm.momentFormat(
                                              new Date(request.shift.end),
                                              "HH:mm"
                                            )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }