<template>
    <div>
      <div v-if="selectedCell.length !==0" class="p-1">
        <div>

          <b-row>
            <div class="font-weight-bold mr-1 " v-if="selectedCell.Subject !== '.'">{{selectedCell.Subject}}</div>
            <b-badge size="sm" class="mr-1" v-if="selectedCell.is_covid_shift" variant="danger">
              COVID-19
            </b-badge>
            <b-badge size="sm" v-if="selectedCell.service.type ==='clinical'" :variant="'light-success'" class="text-capitalize">
                       {{selectedCell.service.type}}
                      </b-badge>
            <b-badge size="sm" v-if="selectedCell.service.type ==='non-clinical'" :variant="'light-info'" class="text-capitalize">
                        {{selectedCell.service.type}}
                      </b-badge>

          </b-row>
          <b-row>
            <div class="font-weight-bold mr-1  " v-if="selectedCell.shift_type">{{selectedCell.shift_type.name}}</div>


          </b-row>
          <b-row class="mt-1">
            <feather-icon icon="CalendarIcon" class="mr-1" />
            {{momentFormat(selectedCell.StartTime,'ddd DD MMMM YYYY')}}
            <!--                        {{moment().format("YYYY Do MM")}}-->
          </b-row>
          <b-row class="mt-1">
            <feather-icon icon="ClockIcon" class="mr-1"  />
            {{momentFormat(selectedCell.StartTime,'HH:mm')}} - {{momentFormat(selectedCell.EndTime,'HH:mm')}}
          </b-row>
          <b-row class="mt-1">
            <feather-icon icon="MapPinIcon" class="mr-1"  />
            <span v-if="selectedCell.is_pcn">{{selectedCell.location.name}}</span>
            <span v-if="!selectedCell.is_pcn">{{selectedCell.practice.name}}</span> 
          </b-row>
          <b-row class="mt-1" v-if="selectedCell.user">
            <feather-icon icon="UsersIcon" class="mr-1"  />
            {{selectedCell.user.first_name +' ' +selectedCell.user.last_name}}
          </b-row>
<!--          <b-row class="mt-1">-->
<!--            <b-badge variant="secondary" v-if="selectedCell.State ==='vacant'">-->
<!--              £180.00 / £220.00-->
<!--            </b-badge>-->
<!--            <b-badge v-else variant="secondary">-->
<!--              £180.00-->
<!--            </b-badge>-->
<!--          </b-row>-->
          <b-row class="mt-1" v-if="selectedCell.State ==='vacant'">
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum       dolore eu fugiat nulla pariatur.
          </b-row>
          <!-- <b-row class="mt-1 applications" v-if="selectedCell.state ==='pending'" @click="viewApplications()"  >

            <spn class="applicationIcon">
              <feather-icon icon="UsersIcon" />
              <span class="">2</span>

            </spn>
            <span class="applicationText">View Applications</span>


          </b-row> -->
            <b-row class="mt-1 ms-2" v-if="selectedCell.state === 'published'&&selectedCell.user === null && selectedCell.is_pcn === false">
            <b-button variant="primary" size='sm' @click="applyMPVacentshifts(selectedCell.Id)">Apply</b-button>
          </b-row>
            <b-row class="mt-1 ms-2" v-if="selectedCell.state === 'published' && selectedCell.user === null && selectedCell.is_pcn === true">
            <b-button variant="primary" size='sm' @click="applyPCNVacentshift(selectedCell.Id)">Apply</b-button>
          </b-row>
        </div>
      </div>
    </div>
</template>

<script>
import shift from '@/apis/modules/shift'
import MomentMixin from '@/mixins/MomentMixin'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BButton,
  BSidebar,
  BModal, BAlert,
  BBadge,
  BButtonGroup, VBToggle, VBModal,
} from 'bootstrap-vue'
import SideBar from "@/views/scheduler/sideBar";
import Ripple from 'vue-ripple-directive'

  export default {
    name: 'content',
    mixins: [MomentMixin],
    components: {
      SideBar,
      BRow,
      BCol,
      BCard,
      BCardText,
      BLink,
      BCardTitle,
      BListGroup,
      BListGroupItem,
      BCardBody,
      BFormGroup,
      BButton,
      BSidebar,
      BModal,
      BAlert,
      BBadge,
      BButtonGroup,
    },
    directives: {
      Ripple,
    },
    data () {
      return {
        selectedCell:[],
        fields: { text: 'CalendarName', value: 'CalendarId' },
        roomData: [
          { CalendarName: 'My Calendar', CalendarId: 1, CalendarColor: '#c43081' },
          { CalendarName: 'Company', CalendarId: 2, CalendarColor: '#ff7f50' },
          { CalendarName: 'Birthday', CalendarId: 3, CalendarColor: '#AF27CD' },
          { CalendarName: 'Holiday', CalendarId: 4, CalendarColor: '#808000' }
        ],
        data: {}
      }
    },
    methods: {
      async viewApplications(){
        this.$root.$emit('indexComponentFormEventTemplateViewApplication') //like this
      },
    applyMPVacentshifts (id) {
      this.$parent.$parent.$parent.applyMPVacenshitsFromCell(id)
    },
    applyPCNVacentshift(id){
      this.$parent.$parent.$parent.applyPCNVcaneshitsFromCell(id)
    }

    },
    mounted () {
      this.selectedCell = this.data
    }
  }
</script>

<style>
.applicationIcon{
  background-color: black; color: white ; border-radius: 5px ; padding: 2px
}
.applicationText{
  padding: 2px;
  margin-left: 5px;
  color:  #1B9AAA;
}
.applications{
  cursor: pointer;
}


</style>